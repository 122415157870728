<!--  -->
<template>
  <div class=''>
    <div style="font-size: 28px;">VIP成员管理</div>
    <!-- <button @click="$router.push({ name: 'examplePage3' })">跳转</button> -->
    <el-divider></el-divider>

    <el-input style="width: 500px" placeholder="请输入用户姓名查询" v-model="search" clearable>
    </el-input>

    <el-table :data="tableData.filter(data => !search || data.realName.toLowerCase().includes(search.toLowerCase()))"
      style="width: 100%">
      <el-table-column type="index"></el-table-column>
      <el-table-column label="用户姓名" prop="realName"></el-table-column>

      <el-table-column label="个人信息">
        <template slot-scope="scope">
          <el-button @click="psInfo(scope.row)" type="primary">{{ '个人信息表' }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="当月报告状态" prop="needReport">
        <template slot-scope="scope">
          <el-button @click="click2Content(scope.row)"
            :type="scope.row.needReport === '10' || scope.row.needReport === 0 ? 'primary' : 'warning'">{{
              scope.row.needReport ===
              0 || scope.row.needReport === '0' ? '查看' : '录入' }}</el-button>
          <!-- 大于25 提示为黄色 -->
          <el-tag :type="scope.row.lastDuration > '25' ? 'warning' : 'success'" disable-transitions
            style="font-size: 14px;" v-if="scope.row.lastDuration">{{ '距离上次报告已经 ' + scope.row.lastDuration + ' 天' }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column label="检测记录查询">
        <template v-slot="scope3">
          <el-popover placement="right" trigger="click" width="auto">
            <div style="text-align: center;font-size: 20px">请选择查询项目</div>
            <el-button @click="getECG(scope3.row.uid)">
              静态心电
            </el-button>
            <el-button @click="getECGHolter(scope3.row.uid)">
              动态心电
            </el-button>
            <el-button @click="getBloodPressure(scope3.row.uid)">
              血压
            </el-button>
            <el-button @click="getBloodOxygen(scope3.row.uid)">
              血氧
            </el-button>
            <el-button @click="getBodyFat(scope3.row.uid)">
              体脂
            </el-button>
            <el-button @click="getUrine(scope3.row.uid)">
              尿检
            </el-button>
            <el-button @click="get8ECG(scope3.row.uid)">
              8导联静态
            </el-button>
            <el-button @click="get8ECGHolter(scope3.row.uid)">
              8导联holter
            </el-button>
            <el-button slot="reference" type="info">查询</el-button>
          </el-popover>
        </template>

      </el-table-column>

      <el-table-column label="备注">
        <template slot-scope="scope">
          <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="scope.row.remark">
          </el-input>
          <el-button @click="updateRemark(scope.row)" type="primary">保存</el-button>
        </template>
      </el-table-column>

      <el-table-column label="操作">
        <template v-slot:default="scope4">
          <el-button size="mini" type="danger" @click="handleDelete(scope4.$index, scope4.row)">删除</el-button>
        </template>
      </el-table-column>

    </el-table>
    <div v-if="psInfoVisible"> <!-- 关闭后销毁dialog框 -->
      <el-dialog :visible.sync="psInfoVisible">
        <ps_information :userId="this.uidCurrent" />
      </el-dialog>
    </div>
  </div>
</template>
    
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import ps_information from '../../personal_module/ps_info/ps_information.vue'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    ps_information
  },
  data() {
    //这里存放数据
    return {
      size: '',
      psInfoVisible: false,
      uidCurrent: '',
      tableData: [
        {
          // 目前都是假数据，需要从接口获得
          uname: 'zyf',
          uid: '2',
          MonthlyReportStatus: '0',  // 当月报告状态
          lastDuration: '2022-11-28 13:10'   //上次报告时间
        },
        {
          uname: 'test',
          uid: '',
          MonthlyReportStatus: '1',  // 当月报告状态
          lastDuration: '2022-12-08 13:10'   //上次报告时间
        }
      ]
    };
  },
  //监听属性 类似于data概念
  computed: {
    crossPlatformStatus: {
      get() { return this.$store.state.module.crossPlatform },
      set(val) { this.$store.commit('module/updateCrossPlatform', val) }
    }
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {

    handleEdit(index, row) {
      console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
    },

    handleClose(done) {
      this.$confirm('确认关闭？')
        // eslint-disable-next-line no-unused-vars
        .then(_ => {
          done();
        })
        // eslint-disable-next-line no-unused-vars
        .catch(_ => { });
    },
    // 向录入详情页传参
    click2Content(e) {
      if (e.needReport == 0 || e.needReport == '0') {
        this.$router.push({
          name: 'ViewMonthlyReport',
          query: {
            uid: e.uid,
            // status: e.MonthlyReportStatus,
            // time: e.MonthlyReportLastTime
          }

        })
      } else {
        this.$router.push({
          name: 'MonthlyReport',
          query: {
            needReport: e.needReport,
            uid: e.uid,
          }
        })
      }

    },
    // 计算离上次报告已过多少天
    DayPassed(date) {
      var today = Date.parse(new Date())
      var startDate = Date.parse(date);
      var days = Math.floor((today - startDate) / (1 * 24 * 60 * 60 * 1000));
      // console.log(days)
      return days
    },
    // 获取个人信息表
    psInfo(e) {
      this.psInfoVisible = true
      this.uidCurrent = e.uid

    },
    // 获取vipList
    getvipList() {
      this.$http({
        url: this.$http.adornUrl('/doctor/doctorVip/getVipDocId'),
        method: 'get',
        params: this.$http.adornParams({
          docId: this.$store.state.doctor_station.docid,
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log(data)
          this.tableData = data.vipUsers
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })
    },

    // 检测项目跳转
    // 检测项目跳转
    getECG(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistorySingoJump',
        query: {
          uid: uid,
        }
      })
    },
    getECGHolter(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistorySingoHolterJump',
        query: {
          uid: uid,
        }
      })
    },

    getBloodFat(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryBloodFatJump',
        query: {
          uid: uid,
        }
      })
    },
    getBodyGlucose(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryBodyGlucoseJump',
        query: {
          uid: uid,
        }
      })
    },
    getBloodOxygen(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryBloodOxygenJump',
        query: {
          uid: uid,
        }
      })
    },
    getBloodPressure(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryBloodPressureJump',
        query: {
          uid: uid,
        }
      })
    },
    getBaby(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryBabyJump',
        query: {
          uid: uid,
        }
      })
    },
    getBio(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryBioJump',
        query: {
          uid: uid,
        }
      })
    },
    getTem(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryTemJump',
        query: {
          uid: uid,
        }
      })
    },
    getBodyFat(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryBodyFatJump',
        query: {
          uid: uid,
        }
      })
    },
    getThreeInOne(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryThreeInOneJump',
        query: {
          uid: uid,
        }
      })
    },
    getUrine(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryUrineJump',
        query: {
          uid: uid,
        }
      })
    },
    get8ECG(e) {
      console.log("看看2023年1月8日");
      this.crossPlatformStatus = true
      this.$router.push({
        name: 'HistoryEightJump',
        query: {
          uid: e,
        }
      })
    },
    get8ECGHolter(e) {
      console.log("看看2023年1月8日");
      // console.log(e);
      this.crossPlatformStatus = true
      this.$router.push({
        name: 'HistoryEightHolterJump',
        query: {
          uid: e,
        }
      })
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // console.log(this.$store.state.doctor_station.docid)
    // this.DayPassed('2022-12-23') 
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getvipList()
  },
  beforeCreate() { }, //生命周期 - 创建之前
  beforeMount() { }, //生命周期 - 挂载之前
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>.drawerTxt {
  margin-left: 10px;
  margin-top: 15px;
  font-size: 25px;
}

.el-table .warning-row {
  background: oldlace;
}</style>
    